// resources/js/toast.ts
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const toastOptions = {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

export const toast = useToast();

export default toastOptions;
